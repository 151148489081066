import Vue from 'vue'
import VueRouter from 'vue-router'
import Places from '../components/Places.vue'
import Place from '../components/Place.vue'
import Login from '../components/Login.vue'
import Settings from '../components/Settings.vue'
import Numerator from '../components/Numerator.vue'

import firebase from 'firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/defaultsite',
    beforeEnter: (to, from, next) => {
      checkToken(to, from, next);
    },
  },
  {
    path: '/',
    name: 'places',
    component: Places,
    beforeEnter: (to, from, next) => {
      checkToken(to, from, next);
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  },
  {
    path: "/place/:place_id",
    component: Place,
    props: true,
    beforeEnter: (to, from, next) => {
      checkToken(to, from, next);
    },
  },
  {
    path: '/place/:place_id/numerators/:numerator_id',
    name: 'Numerator',
    component: Numerator,
    beforeEnter: (to, from, next) => {
      checkToken(to, from, next);
    },
  },

]
function checkToken (to, from, next){
  firebase.auth().onAuthStateChanged(user =>{
    console.log("HIIIII:" + router.currentRoute)
    if(user){
      console.log("HIIIII:" + router.currentRoute)
      next();
    } else{
      console.log("HIIIII:" + router.currentRoute)
      router.push("/login");
    }
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
