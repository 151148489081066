<template>
<div>

        <v-row align="center" justify="center">
          <v-col cols="11" sm="10" md="6">
            <v-card class="elevation-12">
              <v-toolbar color="blue-grey lighten-3" light flat>
                <v-toolbar-title>Einstellungen</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <div class="py-5">
                <h2>Profil</h2>
                <a href="https://myaccount.google.com" target="_blank">Accounteinstellungen (Google)</a><br>
                <a href="https://myaccount.google.com/signinoptions/password" target="_blank">Passwort ändern (Google)</a>
                </div>
                <div>
                  <h2>Daten</h2>
                  <a :href="'mailto:info@contatori.de?subject=Löschung aller Daten des Accounts: ' + user.email +'&amp;body=Hallo%20Contatori,%0D%0Ahiermit beantrage ich die sofortige löschung aller Daten die mit meinem Account in verbindung stehen. %0D%0A%0D%0A'
                  + 'Mein Account:' + '%0D%0AE-Mail: '
                  + user.email + '%0D%0AUId: '
                  + user.uid ">Löschung aller Daten beantragen</a>


                </div>


                <!-- <v-card class="mt-5 mb-5">
                  <v-card-text>
                    <h2><v-icon class="pr-5">mdi-email</v-icon>Anmeldung mit E-Mail-Adresse</h2>
                    <p class="pt-3">Achtung! Momentan nur eingeschränkte Nutzung möglich. Profil bearbeiten und Passwort ändern momentan nicht möglich.</p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="registerWithEmail">Registrieren</v-btn>
                    <v-btn color="blue-grey darken-3" dark @click="loginWithEmail">Anmelden</v-btn>
                  </v-card-actions>
                </v-card> -->

              </v-card-text>

            </v-card>
          </v-col>
        </v-row>

<GetApp :visible="showGetAppDialog" @close="showGetAppDialog=false" />
        <LoginEmail :visible="showLoginEmailDialog" @close="showLoginEmailDialog=false" />
        <RegisterEmail :visible="showRegisterEmailDialog" @close="showRegisterEmailDialog=false" />
  </div>
</template>

<script>
import firebase from "firebase";
import LoginEmail from "../components/dialogs/login/LoginEmail"
import RegisterEmail from "../components/dialogs/login/RegisterEmail"
import GetApp from "../components/dialogs/login/GetApp"
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";


export default {
  data: () => ({
    showLoginEmailDialog: false,
    showRegisterEmailDialog: false,
    showGetAppDialog: false,

  }),
  props: {
    source: String
  },
  computed: {
    ...mapState({
      places: state => state.places.places, // state.<module>.<property>
      user: state => state.user.user
    })
  },
  methods: {
    ...mapActions(["fetchUserData", "updateUserData"]),
    // getFirestoreSlot() {
    //   console.log("hello?????" + this.user.uid);
    //   const usersRef = firestore.collection("users").doc(this.user.uid);
    //   usersRef.get().then(docSnapshot => {
    //     if (docSnapshot.exists) {
    //       usersRef.onSnapshot(doc => {
    //         console.log("YAY fount your slot");
    //         this.$router.push("/");
    //       });
    //     } else {
    //       console.log("now in else");
    //       usersRef
    //         .set({})
    //         .then(function() {
    //           console.log("Document successfully written!");
    //         })
    //         .catch(function(error) {
    //           console.error("Error writing document: ", error);
    //         });
    //     }
    //   });
    // },
    loginWithEmail() {
      console.log("hi")
      this.showLoginEmailDialog = true;
    },
    registerWithEmail() {
      console.log("hi")
      this.showRegisterEmailDialog = true;
    },
    login() {
      console.log("login called");
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken;
          // The signed-in user info.;
          console.log(result.user);
          this.updateUserData(result.user);
          console.log("store????");
          // this.$store.commit('setUser', result.user)
          console.log("real user" + this.user.uid);

          // this.getFirestoreSlot();
          this.$router.push("/");
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
        });
    }
  },
  components: {
    LoginEmail,
    RegisterEmail,
    GetApp,
  }
};
</script>