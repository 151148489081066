import firebase from "firebase";
import Vue from 'vue'

const state = {
  places: [],
  place: {},
};
const getters = {
  getPlaces: (state) => state.places,
  // getPlaceById: (state) => (id) => {
  //   console.log("you bitch");
  //   return state.places.find(place => place.id === id)
  // }
}
const mutations = {
  setPlaces(state, payload) {
    console.log(payload)
    state.places = payload
    //Vue.set(state, 'places', payload)
  },
  setPlace(state, payload) {
    console.log(payload)
    state.place = payload
  },

};
const actions = {
  fetchPlace({ commit }, payload) {
    console.log("fetsching place..." + payload.uid + " and " + payload.id)
    firestore
      .collection("users")
      .doc(payload.uid)
      .collection("places")
      .doc(payload.place_id)
      .get().then(function (doc) {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          let tempplace = {
            city: doc.data().city,
            h_number: doc.data().h_number,
            zip: doc.data().zip,
            street: doc.data().street,
            place_id: doc.id
          }
          commit('setPlace', tempplace);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch(function (error) {
        console.log("Error getting document:", error);
      });
  },
  fetchPlaceList({ commit }, uid) {
    console.log("fetsching places..." + uid)
    firestore
      .collection("users")
      .doc(uid)
      .collection("places")
      .get()
      .then(querySnapshot => {
        let places = []
        console.log("hello?");
        querySnapshot.forEach(place => {
          places.push({
            street: `${place.data().street}`,
            h_number: `${place.data().h_number}`,
            city: `${place.data().city}`,
            zip: `${place.data().zip}`,
            place_id: `${place.id}`,
          });
        });
        commit('setPlaces', places);
      });
  },
  createPlace({ commit }, payload) {
    console.log(payload.uid);
    firestore
      .collection("users/" + payload.uid + "/places")
      .add({
        city: payload.city,
        zip: payload.zip,
        street: payload.street,
        h_number: payload.h_number
      })
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);

      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
        alert("Ein Fehler ist aufgetreten! ;(");
      });
  },
  deletePlace({ commit }, payload){
    console.log("Deleting place");
    console.log(payload)
    firestore.collection("users")
    .doc(payload.uid)
    .collection("places")
    .doc(payload.place_id).delete().then(function() {
      console.log("Document successfully deleted!");
  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
}




// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     user: {},
//     todos: [
//       { id: 1, text: '...', done: true },
//       { id: 2, text: '...', done: false }
//     ]
//   },
//   getters: {
//     doneTodos: state => {
//       return state.todos.filter(todo => todo.done)
//     },
//     getUserId: state => {
//       return state.user.uid
//     },
//   },
//   mutations: {
//     setUser (state, payload) {
//       state.user = payload
//     },
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

// console.log("index of store")
