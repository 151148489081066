import firebase from "firebase";
import Vue from 'vue'

const state = {
  readings: [],
  reading: {},
  readingToDelete: {},
  readingToEdit: {},
};
const getters = {
}
const mutations = {
  setReadings(state, payload) {
    console.log('setting readings')
    state.readings = payload
    console.log('setred readings')
    //Vue.set(state, 'places', payload)
  },
  setReading(state, payload) {
    console.log(payload)
    state.reading = payload
  },
  setReadingToDelete(state, payload) {
    state.readingToDelete = payload
  },
  setReadingToEdit(state, payload) {
    //TempObject requred to detach item from prev observable

    let tempObject = {
      reading: payload.reading,
      inputDate: payload.rawDate.getFullYear() +
      "-" +
      ("0" + (payload.rawDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + payload.rawDate.getDate()).slice(-2),
      reading_id: payload.reading_id,
      imageURL: payload.imageURL,
      imageKey: payload.imageKey,
    }

    state.readingToEdit = tempObject;
    console.log(tempObject)
  },
  removeReadingFromState(state, payload) {
    console.log("hi from remove from state")
    let tempReadings = state.readings;
    let result = [];
    console.log(state.readings.length)
    for (let i = 0; i <= state.readings.length - 1; i++) {
      console.log(tempReadings[i].reading_id + " 0 0 " + payload.reading_id)
      if (tempReadings[i].reading_id != payload.reading_id) {
        result.push(state.readings[i])
        console.log(result)
      } else {
        console.log("found the little shit");
      }
    }
    console.log("all done here")
    // const result = tempReadings.splice( tempReadings.indexOf(tempReadings.filter(reading => reading.reading_id == payload.reading_id);

    // console.log(result);
    state.readings = result
  },

};
const actions = {
  fetchReading({ commit }, payload) {
    console.log(payload)
    console.log("fetching reading")
    firestore
      .collection("users")
      .doc(payload.uid)
      .collection("places")
      .doc(payload.place_id)
      .collection("numerators")
      .doc(payload.numerator_id)

      // .collection("readings")
      .get().then(function (doc) {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          let tempReading = {
            id: doc.id,
            numerator_id: doc.data().numerator_id,
            unit: doc.data().unit,
            description: doc.data().description,
            type: doc.data().type,
          }
          commit('setReading', tempReading);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch(function (error) {
        console.log("Error getting document:", error);
      });
  },
  fetchReadings({ commit }, payload) {
    return new Promise(function (resolve, reject) {
      console.log(payload);

    firestore
      .collection("users")
      .doc(payload.uid)
      .collection("places")
      .doc(payload.place_id)
      .collection("numerators")
      .doc(payload.numerator_id)
      .collection("readings")
      .get()
      .then(querySnapshot => {
        let readings = []
        console.log("hello from fetsching readings");
        querySnapshot.forEach(reading => {

          console.log(reading)
          let date = new Date(`${reading.data().date.seconds}` * 1000)
          readings.push({
            date: date.getUTCDate() + "." + (date.getUTCMonth() + 1) + "." + date.getFullYear(),
            rawDate: date,
            reading: `${reading.data().reading}`,
            reading_id: `${reading.id}`,
            imageURL: `${reading.data().imageURL}`,
            imageKey: `${reading.data().imageKey}`,
          });
        });
        if(readings.length == 0){
          reject(2);
        }
        commit('setReadings', readings);
        console.log(readings);
        resolve(readings);

      });
    })
  },
  createReading({ commit }, payload) {
    console.log("creating reading");
    console.log(payload);

    firestore
      .collection("users")
      .doc(payload.uid)
      .collection("places")
      .doc(payload.place_id)
      .collection("numerators")
      .doc(payload.numerator_id)
      .collection("readings")
      .add({
        reading: payload.reading,
        date: payload.date,
        imageURL: payload.imageURL,
        imageKey: payload.imageKey,
      })
      .then(function (docRef) {
        console.log("Document written with ID: "+  docRef.id + " with image url:");
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
        alert("Ein Fehler ist aufgetreten! ;(");
      });
  },
  updateReading({ commit }, payload) {
    console.log("update reading");
    console.log(payload,state.readingToEdit);

    // Set the "capital" field of the city 'DC'
    firestore
      .collection("users")
      .doc(payload.uid)
      .collection("places")
      .doc(payload.place_id)
      .collection("numerators")
      .doc(payload.numerator_id)
      .collection("readings")
      .doc(payload.reading_id).update({
        "reading": payload.reading,
        "date": payload.date_object,
        "imageURL": payload.imageURL,
        "imageKey": payload.imageKey,
    })
    .then(function() {
        console.log("Document successfully updated!");
    })
    .catch(function(error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    });
  },

  deleteReading({ commit }, payload) {
    return new Promise(function (resolve, reject) {
      console.log("Deleting reading");
      console.log(payload)
      if (payload.imageURL != "" && payload.imageURL != 'undefined') {
        var storageRef = firebase
          .storage()
          .ref(
            payload.uid +
            "/" +
            payload.numerator_id +
            "/" +
            payload.imageKey
          );
        storageRef.delete().then(function () {
          firestore.collection("users")
            .doc(payload.uid)
            .collection("places")
            .doc(payload.place_id)
            .collection("numerators")
            .doc(payload.numerator_id)
            .collection("readings")
            .doc(payload.reading_id)
            .delete().then(function () {
              console.log("Document successfully deleted!");
              resolve(payload)
            }).catch(function (error) {
              console.error("Error removing document: ", error);
            });
        }).catch(function (error) {
          console.log(error)
        });
      } else {
        console.log('deleting document without img')
        firestore.collection("users")
          .doc(payload.uid)
          .collection("places")
          .doc(payload.place_id)
          .collection("numerators")
          .doc(payload.numerator_id)
          .collection("readings")
          .doc(payload.reading_id)
          .delete().then(function () {
            console.log("Document successfully deleted!");
            resolve(payload)
          }).catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions
}




// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     user: {},
//     todos: [
//       { id: 1, text: '...', done: true },
//       { id: 2, text: '...', done: false }
//     ]
//   },
//   getters: {
//     doneTodos: state => {
//       return state.todos.filter(todo => todo.done)
//     },
//     getUserId: state => {
//       return state.user.uid
//     },
//   },
//   mutations: {
//     setUser (state, payload) {
//       state.user = payload
//     },
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

// console.log("index of store")
