<template>
  <div>
    <v-card max-width="900" class="mx-auto mt-md-8" >
      <v-toolbar color="white" dark flat extended class="blue-grey light-4">
        <v-toolbar-title class="white--black">Meine Objekte</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-text-field
          class="search mb-3 d-none d-md-flex"
          hide-details
          label="Search"
          v-model="text"
          single-line
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>

        <template v-slot:extension>
          <v-btn
            fab
            color="blue-grey darken-4"
            bottom
            right
            absolute
            dark
            @click.stop="showCreatePlace=true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-toolbar>

      <v-list two-line subheader>
        <v-subheader inset></v-subheader>
        <v-list-item
          v-for="place in getCertainPlace"
          :key="place.place_id"
          :to="'place/' + place.place_id"
        >
          <v-list-item-avatar>
            <v-icon large color="amber accent-3">mdi-domain</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="place.street + ', ' + place.h_number"></v-list-item-title>
            <v-list-item-subtitle v-text="place.city+ ', ' + place.zip"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <!-- <CheckStatus v-bind:id="place.place_id"></CheckStatus> -->
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <CreatePlace :visible="showCreatePlace" @close="showCreatePlace=false" />
    </v-card>
  </div>
</template>

<style scoped>
.search {
  max-width: 200px;
}
</style>
<script>
// @ is an alias to /src
import CreatePlace from "../components/dialogs/CreatePlace";
import CheckStatus from "../components/golbal/CheckStatus";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {

  computed: {
    ...mapState({
      places: state => state.places.places, // state.<module>.<property>
      user: state => state.user.user
    }),
    getCertainPlace: function() {
      return this.places.filter(place => {
        return place.street.toLowerCase().match(this.text.toLowerCase());
        place.h_number.toLowerCase().match(this.text.toLowerCase());
        place.city.toLowerCase().match(this.text.toLowerCase());
        place.zip.toLowerCase().match(this.text.toLowerCase());
      });
    }
  },
  data: () => ({
    search: "",
    text: "",
     color: 'purple',
    showCreatePlace: false
  }),

  methods: {
    ...mapActions(["fetchPlaceList", "createPlace", "fetchUserData"])
  },
  created() {
    console.log("hi");
    this.fetchUserData().then(data => {
      this.fetchPlaceList(data.uid);
    });
  },
  components: {
    CreatePlace,
    CheckStatus
  }
};
</script>
