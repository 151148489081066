<template>
<div>

  <v-dialog v-model="show" max-width="700px">

    <v-card>
        <v-alert
      text
      :value="alert"
      prominent
      type="error"
      transition="scale-transition"
      icon="mdi-cloud-alert"
    >
      Dieses Objekt enthält noch Zähler. Bitte löschen sie diese, bevor sie das Objekt löschen.
    </v-alert>
      <v-card-title>
        Sind sie sicher?
        <br />
        <span>
          <small style="color: gray;"></small>
        </span>
      </v-card-title>

      <v-card-text>
        <v-spacer></v-spacer>
        <p>Sie können das objekt nur löschen wenn keine Zähler existieren</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click.stop="show=false">Abbrechen</v-btn>
        <v-btn color="error" @click="deletePlaceMethod">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

      <script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  data: () => ({
    alert: false,
  }),
  props: ["visible"],
  computed: {
     ...mapState({
      place: state => state.places.place,
      user: state => state.user.user,
      numerators: state => state.numerator.numerators
    }),
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.alert = false
          this.$emit('close')
        }
      }
    }
  },
    methods: {
      ...mapActions(["deletePlace"]),
      deletePlaceMethod() {
        console.log("deleate plsce")
        console.log(this.numerators.length);
        //TODO: check if there are any numerators in this places
        if (this.numerators.length  == 0) {
          console.log(this.place)
          let payload = { uid: this.user.uid, place_id: this.place.place_id };
          this.deletePlace(payload);
          this.show = false;
          this.$router.replace("/");
        } else{
          this.alert = true
        }
      },
    },

    created() {
      console.log("deleate place dialog created")
    }

  }
</script>