<template>
  <v-footer
    color="blue-grey lighten-5"
    padless
    class="mt-10"
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link"
        color="black"
        text
        rounded
        ripple="true"
        :href="link.url" target="_blank"
        class="my-2"
      >
        {{ link.text }}
      </v-btn>
      <v-col
        class="blue-grey lighten-4 py-4 text-center black--text"
        cols="12"
      >
        © {{ new Date().getFullYear() }} <strong>Contatori</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
  export default {
    data: () => ({
      links: [
        {text: 'Datenschutzerklärung',
        url: 'https://contatori.de/Datenschutz_app.html'},
        {text: 'Hilfe-Center',
        url: 'https://contatori.de/help.html'}
      ],
    }),
  }
</script>