<template>

  <v-app>


  <Nav v-if="displayNav"/>
    <v-content>
      <!-- <Places/> -->
      <router-view></router-view>
    </v-content>
    <Footer/>
  </v-app>
</template>

<script>

import firebase from 'firebase'
import Nav from './components/golbal/Nav'
import Footer from './components/golbal/Footer'




export default {
  name: 'App',
    data: () => ({
      displayNav: false,
      user: {},
  }),


  components: {
    Nav,
    Footer
  },
  created(){
    firebase.auth().onAuthStateChanged(user =>{
      if(user){
        console.log("this is the user", user)
        this.displayNav = true
      } else{
        console.log("this is the user", user)
        this.displayNav = false
      }
    })
  },


};
</script>
