<template>
  <div>
    <v-dialog v-model="show" max-width="700px">
      <v-card>

        <v-card-title>
          Apple App Installieren
          <br />
          <span>
            <small style="color: gray;"></small>
          </span>
        </v-card-title>

        <v-card-text>
          <v-spacer></v-spacer>
          <p><b>1.</b> <v-icon class="">mdi-apple-safari</v-icon> Safari Browser auf Apple Gerät öffnen</p>
          <p><b>2.</b> <a href="https://app.contatori.de">https://app.contatori.de</a> eingeben und Webseite öffnen</p>
          <p><b>3.</b> Melden sie sich mit ihren Login Daten an.</p>
          <p><b>4.</b> Sobald sie angemeldet sind klicken sie in der unteren Leiste des browsers auf dieses Icon: <v-icon class="">mdi-export-variant</v-icon></p>
           <v-img src="../../../assets/docs/IMG_4245.jpg" aspect-ratio="1.2" contain></v-img>
          <p><b>5.</b> klicken sie auf: "Zum Home Bildschirm"</p>
          <v-img src="../../../assets/docs/IMG_4246.jpg" aspect-ratio="1.2" contain></v-img>
          <p><b>6.</b> klicken sie auf: "Hinzufügen"</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn text color="primary" @click.stop="show=false">Abbrechen</v-btn>
          <v-btn color="error" @click="deletePlaceMethod">Löschen</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

      <script>
export default {
  data: () => ({
  }),
  created(){
    console.log("dhfhsudfjhsdjfhgsjdhfg")
  },
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {

          this.$emit("close");
        }
      }
    }
  },
};
</script>