<template>
  <v-dialog v-model="show" max-width="700px">
    <v-card>
      <v-card-title>Neuen Zähler erstellen</v-card-title>
      <v-card-text>
        <v-spacer></v-spacer>

        <template>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="newNumerator.numerator_id"
                  :rules="nameRules"
                  label="Zählernummer*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="0" md="1"></v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="newNumerator.type"
                  :rules="numberRules"
                  label="Type*"
                  placeholder="z.B. Wasser"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-6" no-gutters>
              <v-col class="d-flex" cols="12" md="4">
                <v-select :items="units" :rules="reqRules" v-model="newNumerator.unit" label="Maßeinheit*" required></v-select>
              </v-col>
              <v-col cols="0" md="1"></v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="newNumerator.description"
                  :rules="nameRules"
                  placeholder="z.B. Whohnung EG"
                  label="Beschreibung/ Ort*"
                ></v-text-field>
              </v-col>
              <v-col cols="0" md="1"></v-col>
            </v-row>
            <!-- TODO: ADD YOUNITS SELECTION -->
          </v-form>
        </template>

        <small class="grey--text">*Pflichtfeld</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click.stop="show=false">Abbrechen</v-btn>
        <v-btn color="primary" @click="validate">Zähler erstellen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  data: () => ({
    text: "",
    units: ['kWh - (kilowattstunde)', 'm³ - (Kubikmeter)', 'm² - (Quadratmeter)', 'kg - (kilogramm)', 'l - (Liter)', 'hl - (Hectoliter)'],
    newNumerator: {
      numerator_id: "",
      type: "",
      description: "",
      uid: "",
      place_id: "",
      unit: ""
      //selection: kWh, m³, gas auch m³, kg, l,
    },
  reqRules: [
      v => !!v || "Pflichtfeld",
    ],
    nameRules: [
      v => !!v || "Pflichtfeld",
      v =>
        (v && v.length <= 20) ||
        "Beschreibung darf nicht mehr als 20 Zeischen haben"
    ],
    numberRules: [
      v => !!v || "Pflichtfeld",
      v =>
        (v && v.length <= 50) ||
        "Beschreibung darf nicht mehr als 50 Zeischen haben"
    ]
  }),
  methods: {
    ...mapActions(["createNumerator", "fetchNumerators"]),

    validate() {
      if (this.$refs.form.validate()) {
        let payload = {
          uid: this.user.uid,
          place_id: this.$route.params.place_id
        };
        console.log(this.newNumerator.unit);
        this.newNumerator.uid = this.user.uid;
        this.newNumerator.place_id = this.place.place_id;
        this.createNumerator(this.newNumerator);

        this.show = false;
        this.reset();
        this.fetchNumerators(payload);
      }
    },
    reset() {
      this.$refs.form.reset();
    }
  },
  props: ["visible"],
  computed: {
    ...mapState({
      user: state => state.user.user,
      place: state => state.places.place,
      numerators: state => state.numerator.numerators
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  }
};
</script>