<template>
  <v-dialog v-model="show" max-width="700px">
    <v-card>
      <v-card-title>Zählerstand bearbeiten</v-card-title>
      <v-card-text>
        <v-spacer></v-spacer>

        <template>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="readingToEdit.reading"
                  :value="readingToEdit.reading"
                  :rules="realNumberRules"
                  label="Zählerstand*"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1"></v-col>
              <v-col cols="12" sm="6" md="5">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-click="false"
                  :close-on-content-click="false"
                  :return-value.sync="readingToEdit.inputDate"
                  :rules="nameRules"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="readingToEdit.inputDate"
                      :rules="nameRules"
                      label="Datum*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="readingToEdit.inputDate"
                    no-title
                    scrollable
                    :show-current="readingToEdit.inputDate"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="warn" @click="menu = false">Abbrechen</v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.menu.save(readingToEdit.inputDate)"
                    >Speichern</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!-- <v-row class="mb-6" no-gutters>
              <v-col v-if="readingToEdit.imageKey == ''" cols="12" md="12">
                <v-file-input
                  :rules="uploadRules"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Foto Hochladen"
                  prepend-icon="mdi-camera"
                  label="Foto"
                  v-model="u"
                ></v-file-input>
              </v-col>
              <v-col v-if="readingToEdit.imageKey != ''" cols="12" md="12">

                <v-chip v-if="imageChip" close class="ma-2" @click:close="deleteThisImage()" @click="openThisImage(readingToEdit.imageURL)">
                  <v-avatar left>
                    <v-icon>mdi-camera</v-icon>
                  </v-avatar>{{readingToEdit.imageKey}}
                </v-chip>
              </v-col>

              <v-col cols="0" md="1"></v-col>
            </v-row> -->
          </v-form>
        </template>

        <small class="grey--text">*Pflichtfeld</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click.stop="show=false">Abbrechen</v-btn>
        <v-btn :loading="loading"
      :disabled="loading" color="primary" @click="validate">Zählerstand ändern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  data: () => ({
    date: "2013-02-22",
    menu: false,
    loading: false,
    imageChip: true,
    thisReading: {
      date: new Date(),
      inputDate: "",
      reading: "",
      uid: "",
      place_id: "",
      numerator_id: "",
      image: null,
      imageURL: "",
      imageKey: ""
    },

    uploadRules: [
      value =>
        !value ||
        value.size < 3000000 ||
        "Das Bild sollte nicht größer als 3 MB sein!"
    ],
    nameRules: [v => !!v || "Pflichtfeld"],
    numberRules: [
      v => !!v || "Bitte Zahl eingeben",
      v =>
        (v && v.length <= 50) ||
        "Beschreibung darf nicht mehr als 50 Zeischen haben"
    ],
    realNumberRules: [
      v => !!v || "Bitte Zahl eingeben",
      v => /^[0-9]*\,{0,1}[0-9]{0,10}$/.test(v) || "Nur Zahlen!"
    ]
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      readingToEdit: state => state.readings.readingToEdit
    }),
    show: {
      get() {
        console.log("wtf where am i?", this.date);
        this.date = this.readingToEdit.inputDate;
        console.log("wtf where am i2? ", this.date);
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    ...mapActions(["createReading", "fetchReadings", "updateReading"]),
    validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        var that = this;
        this.readingToEdit.date_object = new Date(this.readingToEdit.inputDate);
        this.readingToEdit.uid = this.user.uid;
        this.readingToEdit.place_id = this.$route.params.place_id;
        this.readingToEdit.numerator_id = this.$route.params.numerator_id;
        this.updateReading(this.readingToEdit).then(function() {
          setTimeout(function() {
            that.fetchReadings(that.readingToEdit).then(function() {
                that.show = false;
                that.loading = false;
        });}, 3000);}
        );

        console.log("update data tolol: ", this.readingToEdit);
      }
    },
        openThisImage(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    deleteThisImage() {
      //TODO: call the delete image from store action
      //TODO: then deleat the chip
      imageChip = false
      //now display the image chooser
    },
    reset() {
      this.thisReading.image = null;
      this.thisReading.imageURL = "";
      this.$refs.form.reset();
    }
  },
  props: ["visible"],

};
</script>
