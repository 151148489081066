import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import firebase from 'firebase';
import colors from 'vuetify/lib/util/colors'
const save = require('./save');

// Initialize Firebase
firebase.initializeApp(save.firebaseConfig());
firebase.analytics();

var firestore = firebase.firestore();
try {
  let app = firebase.app();
  let features = ['auth', 'database', 'messaging', 'storage'].filter(feature => typeof app[feature] ===
      'function');
  console.log(`Firebase SDK loaded with ${features.join(', ')}`);
} catch (e) {
  console.error(e);
  console.error('Error loading the Firebase SDK, check the console.');
}
window.firestore = firestore;

Vue.config.productionTip = false



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
