<template>
  <div>
    <v-dialog v-model="show" max-width="700px">
      <v-card>
                <v-alert
      text
      :value="alert"
      prominent
      type="error"
      transition="scale-transition"
      icon="mdi-cloud-alert"
    >
      Login Daten falsch oder dieser Benutzer existiert nicht.
    </v-alert>
        <v-card-title>
          Anmelden
          <br />
          <span>
            <small style="color: gray;"></small>
          </span>
        </v-card-title>

        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="12">
                       <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="E-mail"
            required
          ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Passwort"
                counter
                required
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-col>
          </v-row>
                        </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click.stop="show=false">Abbrechen</v-btn>
        <v-btn color="blue-grey darken-3" dark @click="loginWithEmail">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

      <script>
      import firebase from "firebase";

export default {
  data: () => ({
    show1: false,
    password: "",
    email: '',
    alert: false,
    valid: true,
    rules: {
      required: value => !!value || "Pflichtfeld",
      min: v => v.length >= 8 || "Mindestens 8 Zeichen",
       email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Email Adresse falsch.'
          },
    }
  }),
  created() {
    console.log("dhfhsudfjhsdjfhgsjdhfg");
  },
  methods:{
    loginWithEmail(){
      if (this.$refs.form.validate()) {
      console.log("logging in with email", this.email, this.password);
      const auth = firebase.auth();

      const promis = auth.signInWithEmailAndPassword(this.email, this.password);
      promis.then(data => {this.show = false; this.$router.push("/");}).catch(e => this.alert = true);
      }
    }
  },
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.alert = false
          this.$emit("close");
        }
      }
    }
  }
};
</script>