<template>
  <div>
    <div class="mx-auto text-center pt-12" v-if="numerator.numerator_id === undefined">
      <h2>Zähler nicht gefunden!</h2>
      <p>Stellen sie sicher das sie den richtigen Account verwenden.</p>
    </div>
    <div v-if="numerator.numerator_id != undefined">
    <BreadCrumbs :visible="showBreadCrumbs" />

    <v-card max-width="900" class="mx-auto">
      <v-toolbar color="white"  dark extended class="blue-grey light-4">
        <v-btn icon color="white" :to="{ path: '/place/'+ this.$route.params.place_id}">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="white--black pt-5">
          <v-row no-gutters>
            <v-col cols="12" md="6">
              {{numerator.numerator_id}} ({{numerator.type}})
              <br />
              <span>
                <small style="color: white;">{{numerator.description}}</small>
              </span>
            </v-col>
          </v-row>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn dark icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item disabled>
              <v-list-item-title>Zähler Bearbeiten</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="showDeleteNumeratorDialog=true">
              <v-list-item-title>Zähler Löschen</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="downloadItem()">
              <v-list-item-title>
                  QR-Code generieren
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <template v-slot:extension>
          <v-btn
            fab
            color="blue-grey darken-4"
            bottom
            right
            absolute
            @click.stop="showCreateReading=true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-toolbar>
      <div class>
        <v-data-table
          :headers="headers"
          :items="readings"
          class="pt-10"
          sortBy="reading"
          sortDesc="false"
          :loading="isLoading"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Messungen für {{numerator.numerator_id}}</v-toolbar-title>
              <div class="flex-grow-1"></div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                class="search d-none d-md-flex"
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              v-if="validateImageUrl(item.imageURL)"
              icon
              color="black"
              @click="openThisImage(item.imageURL)"
            >
              <v-icon>mdi-image-outline</v-icon>
            </v-btn>
            <v-btn v-if="!validateImageUrl(item.imageURL)" icon color="gray" @click.stop="editThisReading(item)">
              <v-icon>mdi-camera-plus-outline</v-icon>
            </v-btn>

            <v-btn icon color="black" @click.stop="editThisReading(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn icon color="black" @click.stop="deleteThisReading(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>

      <DeleteNumerator
        :visible="showDeleteNumeratorDialog"
        @close="showDeleteNumeratorDialog=false"
      />
      <DeleteReading :visible="showDeleteReadingDialog" @close="showDeleteReadingDialog=false" />
      <CreateReading :visible="showCreateReading" @close="showCreateReading=false" />
       <EditReading :visible="showEditReading" :thisReadingAsProp="thisReadingAsProp" @close="showEditReading=false" />
    </v-card>
    </div>
  </div>
</template>
<style scoped>
.search {
  max-width: 200px;
}
.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
  opacity: 0 !important;
}
</style>
<script>
// @ is an alias to /src
import Axios from 'axios'
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import DeleteNumerator from "../components/dialogs/DeleteNumerator";
import CreateReading from "../components/dialogs/CreateReading";
import BreadCrumbs from "../components/golbal/BreadCrumbs";
import DeleteReading from "../components/dialogs/DeleteReading";
import EditReading from "../components/dialogs/edit/EditReading"

export default {
  computed: {
    ...mapState({
      places: state => state.places.places, // state.<module>.<property>
      user: state => state.user.user,
      place: state => state.places.place,
      numerator: state => state.numerator.numerator,
      readings: state => state.readings.readings,
      readingToEdit: state => state.readings.readingToEdit
    })
  },
  data: () => ({
    valid: true,
    thisReadingAsProp: null,
    showDeleteNumeratorDialog: false,
    showDeleteReadingDialog: false,
    showCreateReading: false,
    showEditReading: false,
    showBreadCrumbs: true,
    search: "",

    isLoading: false,
    headers: [
      { text: "Zählerstand", value: "reading", sortable: false },
      {
        text: "Datum",
        align: "left",
        value: "date",
        sortable: false
      },
      { text: "", align: "right", sortable: false, value: "action" }
    ]
  }),

  methods: {
    ...mapMutations(["setReadingToDelete", "setReadingToEdit"]),
    ...mapActions([
      "fetchReadings",
      "fetchNumerator",
      "fetchPlace",
      "fetchUserData"
    ]),
    validateImageUrl(url) {
      if (url && url != "undefined") {
        return true;
      } else {
        return false;
      }
    },
    downloadItem () {
      // console.log("https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=" + "https://" + window.location.hostname + this.$route.path)
      let url = "https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=" + "https://" + window.location.hostname + this.$route.path
      let label = "qr-code_" + this.place.street + "_" + this.place.h_number + "_" + this.$route.params.numerator_id
        Axios.get(url, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: "image/png" })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
    editThisReading(payload) {
      // this.thisReadingAsProp = payload
      this.$store.commit("setReadingToEdit", payload)
        console.log("yay2");
        if(this.readingToEdit != null || this.readingToEdit != "undefined"){
          console.log(this.readingToEdit)
          this.showEditReading = true;
        }
    },
    deleteThisReading(payload) {
      console.log(payload);
      this.$store.commit("setReadingToDelete", payload);
      this.showDeleteReadingDialog = true;
    },
    openThisImage(url) {
      var win = window.open(url, "_blank");
      win.focus();
    }
  },
  created() {
    this.fetchUserData().then(data => {
      let payload = {
        uid: this.user.uid,
        place_id: this.$route.params.place_id,
        numerator_id: this.$route.params.numerator_id
      };
      this.fetchNumerator(payload);
      this.fetchReadings(payload);
      this.fetchPlace(payload);

      this.isLoading = false;
    });

    this.showBreadCrumbs = true;
  },
  components: {
    DeleteNumerator,
    DeleteReading,
    CreateReading,
    BreadCrumbs,
    EditReading
  }
};
</script>
