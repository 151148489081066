<template>
  <div>
    <v-dialog v-model="show" max-width="700px">
      <v-card>
        <v-card-title>
          Sind sie sicher?
          <br />
          <span>
            <small style="color: gray;"></small>
          </span>
        </v-card-title>

        <v-card-text>
          <v-spacer></v-spacer>
          <p>Wollen sie diese Messung wirklich unwiderruflich löschen?</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.stop="show=false">Nicht löschen</v-btn>
          <v-btn color="error" @click="deleteReadingMethod()" :loading="loading"
      :disabled="loading"> Ja, löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

      <script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  data: () => ({
    loading: false,
  }),
  props: ["visible"],
  computed: {
    ...mapState({
      place: state => state.places.place,
      user: state => state.user.user,
      readings: state => state.readings.readings,
      numerator: state => state.numerator.numerator,
      readingToDelete: state => state.readings.readingToDelete
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    ...mapActions(["deleteReading", "fetchReadings"]),
        ...mapMutations([
      'removeReadingFromState',
    ]),
    deleteReadingMethod() {
      this.loading= true;
      console.log("deleate Reading: " + this.readingToDelete.reading);
      var that = this;

      let payload = {
        uid: this.user.uid,
        place_id: this.place.place_id,
        numerator_id: this.$route.params.numerator_id,
        reading_id: this.readingToDelete.reading_id,
        reading: this.readingToDelete.reading,
        imageURL: this.readingToDelete.imageURL,
        imageKey: this.readingToDelete.imageKey,
      };
      this.deleteReading(payload).then(function(payload) {
              that.$store.commit('removeReadingFromState', payload)
                    that.show = false;
                    that.loading = false;
            })
            .catch(function(error) {
              console.error("Error uploading image?: ", error);
              that.loading = false;
            });



    }
  },

  created() {
    console.log("deleate READING CREATED GOOOOOOOOO");
  }
};
</script>