<template>
  <div>
    <BreadCrumbs :visible="showBreadCrumbs" />

    <v-card max-width="900" class="mx-auto">
      <v-toolbar color="white" dark extended class="blue-grey light-4">
        <v-btn text icon color="white" to="/">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="white--black pt-5">
          {{place.street}} {{place.h_number}}
          <br />
          <span>
            <small style="color: white;">{{place.city}}, {{place.zip}}</small>
          </span>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn dark icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item disabled>
              <v-list-item-title>{{place.street}} {{place.h_number}} Bearbeiten</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="showDeletePlaceDialog=true">
              <v-list-item-title>{{place.street}} {{place.h_number}} Löschen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <template v-slot:extension>
          <v-btn
            fab
            color="blue-grey darken-4"
            bottom
            right
            absolute
            @click.stop="showCreateNumerator=true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-toolbar>
      <div class>
        <v-data-table
          :headers="headers"
          :items="numerators"
          class="elevation-1 pt-10"
          :loading="isLoading"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Zähler</v-toolbar-title>
              <div class="flex-grow-1"></div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                class="search"
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
          <!-- <template v-slot:item.numerator_id="{ item }">
            <td>{{ item.numerator_id }}</td>
          </template>
          <template v-slot:item.description="{ item }">
            <td>{{ item.description }}</td>
          </template>

          <template v-slot:item.type="{ item }">
            <td>{{ item.type }}</td>
          </template>-->
          <template v-slot:item.action="{ item }">
            <v-row no-gutters justify="left">
              <v-col>
              </v-col>
              <v-col md="auto">
                <CheckStatus v-bind:id="place.place_id" v-bind:numerator_id="item.id"></CheckStatus>
              </v-col>
              <v-col md="auto">
                 <v-btn text icon color="blue-grey darken-4"  :to="'/place/' + place.place_id + '/numerators/' + item.id">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>

              </v-col>
            </v-row>
            <!-- <v-icon
              @click="deleteIngestion(item)"
            >
              mdi-delete
            </v-icon>-->
          </template>
        </v-data-table>
      </div>

      <DeletePlace :visible="showDeletePlaceDialog" @close="showDeletePlaceDialog=false" />
      <CreateNumerator :visible="showCreateNumerator" @close="showCreateNumerator=false" />
    </v-card>
  </div>
</template>
<style scoped>
.search {
  max-width: 200px;
}
/* .v-data-table-header-mobile tr{
  display: none!important;
} */
</style>
<script>
// @ is an alias to /src

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import DeletePlace from "../components/dialogs/DeletePlace";
import CreateNumerator from "../components/dialogs/CreateNumerator";
import BreadCrumbs from "../components/golbal/BreadCrumbs";
import CheckStatus from "../components/golbal/CheckStatus";

export default {
  computed: {
    ...mapState({
      places: state => state.places.places, // state.<module>.<property>
      user: state => state.user.user,
      place: state => state.places.place,
      numerators: state => state.numerator.numerators
    })
  },
  data: () => ({
    valid: true,
    showDeletePlaceDialog: false,
    showCreateNumerator: false,
    showBreadCrumbs: true,
    search: "",

    isLoading: true,
    headers: [
      {
        text: "Zählernummer",
        align: "left",
        value: "numerator_id",
        sortable: true
      },
      { text: "Beschreibung", value: "description", sortable: true },
      { text: "Type", value: "type", sortable: true },
      { text: "", align: "right", sortable: false, value: "action" }
    ]
  }),

  methods: {
    ...mapActions(["fetchPlace", "fetchNumerators", "fetchUserData"])
  },
  created() {
    this.fetchUserData().then(data => {
      let payload = {
        uid: this.user.uid,
        place_id: this.$route.params.place_id
      };
      this.fetchPlace(payload);
      this.fetchNumerators(payload);
      this.isLoading = false;
    });
  },
  components: {
    DeletePlace,
    CreateNumerator,
    BreadCrumbs,
    CheckStatus
  }
};
</script>
