<template>
  <div>
    <v-app-bar color="blue-grey lighten-3" thick light>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Contatori</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>-->

      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="mr-2">
            <v-avatar size="36px">
              <v-img :src="user.photoURL"></v-img>
            </v-avatar>
          </v-btn>
        </template>

        <v-list>

          <v-list-item @click="signOut">
             <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tab>Tab 1</v-tab>
          <v-tab>Tab 2</v-tab>
          <v-tab>Tab 3</v-tab>
        </v-tabs>
      </template> -->
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="user.photoURL"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{user.displayName}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>


        <v-list-item link @click.native="$router.push('/')">
          <v-list-item-icon>
            <v-icon>mdi-counter</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Zählerstände</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item disabled>
          <v-list-item-icon>
            <v-icon>mdi-calculator</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Nebenkostenabrechnung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click.native="$router.push('/settings')">
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Einstellungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="signOut" link>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import firebase from "firebase";
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';


export default {
  computed: mapState({
      user: state => state.user.user
    }),
  methods: {
    ...mapActions(['fetchUserData', 'updateUserData']),
    signOut(){
      firebase.auth().signOut().then(function() {
        // this.$router.push("/login");
      }, function(error) {
        alert("cant logout now: N4a3v2");
      });
    }
  },
  created() {
    if(this.user != undefined)this.fetchUserData()
  },

  data: () => ({
    drawer: null,
    items: [
      { title: "Zählerstände", icon: "mdi-counter", rout:"/" },
      { title: "Abmelden", icon: "mdi-logout", rout:"signe" }
    ],
    topLeftMenu: [
          { title: 'Abmelden', icon: 'mdi-logout' },
          { title: 'Zählerstände', icon: 'mdi-settings' },
        ],
  })
};
</script>