module.exports = {

    firebaseConfig: function() {
        console.log("getting this data from a save file ;)")
        var firebaseConfig = {
            apiKey: "AIzaSyDJHa_qd28muromBAb9RqPyIk12AJevPtQ",
            authDomain: "nebenkosten-6b4ec.firebaseapp.com",
            databaseURL: "https://nebenkosten-6b4ec.firebaseio.com",
            projectId: "nebenkosten-6b4ec",
            storageBucket: "nebenkosten-6b4ec.appspot.com",
            messagingSenderId: "718744999105",
            appId: "1:718744999105:web:08cf6d12a3bd0914603040",
            measurementId: "G-T3JG3EDJYV"
          };
       return firebaseConfig;
    }
 }