<template>
  <v-dialog v-model="show" max-width="700px">
    <v-card>
      <v-card-title>Neuen Zählerstand für {{ numerator.numerator_id }}</v-card-title>
      <v-card-text>
        <v-spacer></v-spacer>

        <template>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newReading.reading"
                  :rules="realNumberRules"
                  label="Zählerstand*"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="1"></v-col>
              <v-col cols="12" sm="6" md="5">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  :rules="nameRules"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      :rules="nameRules"
                      label="Datum*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">Abbrechen</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">Bestätigen</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="mb-6" no-gutters>
              <v-col cols="12" md="12">
                <v-file-input
                  :rules="uploadRules"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Foto Hochladen"
                  prepend-icon="mdi-camera"
                  label="Foto"
                  v-model="newReading.image"
                ></v-file-input>
              </v-col>
              <v-col cols="0" md="1"></v-col>
            </v-row>
            <!-- TODO: ADD YOUNITS SELECTION -->
          </v-form>
        </template>

        <small class="grey--text">*Pflichtfeld</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click.stop="show=false">Abbrechen</v-btn>
        <v-btn :loading="loading"
      :disabled="loading" color="primary" @click="validate">Zählerstand hinzufügen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  data: () => ({
    text: "",
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    loader: null,
    loading: false,
    newReading: {
      date: new Date(),
      reading: "",
      uid: "",
      place_id: "",
      numerator_id: "",
      image: null,
      imageURL: "",
      imageKey: "",
    },
    uploadRules: [
      value =>
        !value ||
        value.size < 3000000 ||
        "Das Bild sollte nicht größer als 3 MB sein!"
    ],
    nameRules: [v => !!v || "Pflichtfeld"],
    numberRules: [
      v => !!v || "Bitte Zahl eingeben",
      v =>
        (v && v.length <= 50) ||
        "Beschreibung darf nicht mehr als 50 Zeischen haben"
    ],
    realNumberRules: [
      v => !!v || "Bitte Zahl eingeben",
      v => /^[0-9]*\,{0,1}[0-9]{0,10}$/.test(v) || "Nur Zahlen!"
    ]
  }),
  methods: {
    ...mapActions(["createReading", "fetchReadings"]),
    uploadImage(newReading) {
      return new Promise(function(resolve, reject) {
        // Only `delay` is able to resolve or reject the promise
        var imageKey = newReading.reading + "++" + Date.now();
        newReading.imageKey = imageKey;
        var storageRef = firebase
          .storage()
          .ref(
            newReading.uid +
              "/" +
              newReading.numerator_id +
              "/" +
              imageKey
          );

        var metadata = {
          contentType: "image/jpeg"
        };
        var uploadTask = storageRef.put(newReading.image, metadata);

        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
            // https://firebase.google.com/docs/storage/web/upload-files
          },
          function(error) {
            console.log(error);
          },
          function() {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                newReading.imageURL = downloadURL;
                resolve(newReading);
              });
          }
        );
      });
    },
    validate() {

      if (this.$refs.form.validate()) {
              this.loading = true;

        this.newReading.date = new Date(this.date);
        this.newReading.uid = this.user.uid;
        this.newReading.place_id = this.$route.params.place_id;
        this.newReading.numerator_id = this.$route.params.numerator_id;

        if (this.newReading.image != null) {
          console.log(this.newReading)
          let localPayload = null;
          var that = this;
          this.uploadImage(this.newReading)
            .then(function(payload) {
              localPayload = payload;
              console.log("img uploaded with key: "+ payload.imageKey);

              console.log(that);
              that.createReading(payload);
              that.fetchReadings(payload);

              that.show = false;
              that.loading = false;
              that.reset();
            })
            .catch(function(error) {
              console.error("Error uploading image?: ", error);
            });
          console.log(localPayload);
        } else {
          console.log("no image found: so url is:"+ this.newReading.imageURL )
          this.createReading(this.newReading);
          this.fetchReadings(this.newReading);

          this.show = false;
          this.loading = false;
          this.reset();
        }
      }
    },
    reset() {
      this.newReading.image = null;
      this.newReading.imageURL = "";
      this.$refs.form.reset();
    }
  },
  props: ["visible"],
  computed: {
    ...mapState({
      user: state => state.user.user,
      place: state => state.places.place,
      numerators: state => state.numerator.numerators,
      numerator: state => state.numerator.numerator
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  created() {
    console.log("HI");
  }
};
</script>
