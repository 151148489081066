import firebase from "firebase";
import Vue from 'vue'

const state = {
  numerators: [],
  numerator: {},
};
const getters = {
}
const mutations = {
  setNumerators(state, payload) {
    console.log(payload)
    state.numerators = payload;
    //Vue.set(state, 'places', payload)
  },
  setNumerator(state, payload) {
    console.log(payload)
    state.numerator = payload;
  },
  setNumeratorStatus(state, payload) {
    console.log(payload)
    state.numerator.status = payload;
  },

};
const actions = {
  fetchNumerator({ commit }, payload) {
    console.log(payload)
    console.log("fetching numerator")
    firestore
      .collection("users")
      .doc(payload.uid)
      .collection("places")
      .doc(payload.place_id)
      .collection("numerators")
      .doc(payload.numerator_id)
      .get().then(function (doc) {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          let tempNumerator = {
            id: doc.id,
            numerator_id: doc.data().numerator_id,
            unit: doc.data().unit,
            description: doc.data().description,
            type: doc.data().type,
          }
          commit('setNumerator', tempNumerator);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch(function (error) {
        console.log("Error getting document:", error);
      });
  },
  fetchNumerators({ commit }, payload) {
    return new Promise(function (resolve, reject) {
      console.log("fetsching numerators")
      console.log(payload)
    firestore
      .collection("users")
      .doc(payload.uid)
      .collection("places")
      .doc(payload.place_id)
      .collection("numerators")
      .get()
      .then(querySnapshot => {
        let numerators = []
        console.log("hello from fetsching numerators");
        querySnapshot.forEach(numerator => {
            numerators.push({
            numerator_id: `${numerator.data().numerator_id}`,
            description: `${numerator.data().description}`,
            type: `${numerator.data().type}`,
            id: `${numerator.id}`
          });

        });
        if(numerators.length == 0){
          reject(2);
        }
        console.log(numerators);
        commit('setNumerators', numerators);
        resolve(numerators)
      }).catch( error => {
        reject(error)
      });
    })
  },
  createNumerator({ commit }, payload) {
    console.log("creating numerator");
    console.log(payload);

    firestore
    .collection("users")
    .doc(payload.uid)
    .collection("places")
    .doc(payload.place_id)
    .collection("numerators")
    .add({
        numerator_id: payload.numerator_id,
        type: payload.type,
        description: payload.description,
        unit: payload.unit
      })
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
        alert("Ein Fehler ist aufgetreten! ;(");
      });
  },
  deleteNumerator({ commit }, payload){
    console.log("Deleting numerator");
    console.log(payload)
    firestore.collection("users")
    .doc(payload.uid)
    .collection("places")
    .doc(payload.place_id)
    .collection("numerators")
    .doc(payload.numerator_id).delete().then(function() {
      console.log("Document successfully deleted!");
  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
}




// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     user: {},
//     todos: [
//       { id: 1, text: '...', done: true },
//       { id: 2, text: '...', done: false }
//     ]
//   },
//   getters: {
//     doneTodos: state => {
//       return state.todos.filter(todo => todo.done)
//     },
//     getUserId: state => {
//       return state.user.uid
//     },
//   },
//   mutations: {
//     setUser (state, payload) {
//       state.user = payload
//     },
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

// console.log("index of store")
