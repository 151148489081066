<template>
  <div>
    <v-dialog v-model="show" max-width="700px">
      <v-card>
        <v-alert
          text
          :value="alert"
          prominent
          type="error"
          transition="scale-transition"
          icon="mdi-cloud-alert"
        >{{errorMessage}}</v-alert>
        <v-card-title>
          Registrieren
          <br />
          <span>
            <small style="color: gray;"></small>
          </span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="firstname"
                  :rules="[rules.required]"
                  label="Vorname"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="lastname"
                  :rules="[rules.required]"
                  label="Nachname"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" class="pb-0">
                <v-text-field
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  label="E-Mail-Adresse"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="pt-0">
                <v-text-field
                  v-model="email2"
                  :rules="[rules.required, emailConfirmationRule]"
                  label="E-Mail-Adresse wiederholen"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="pb-0">
                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Passwort"
                  counter
                  required
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="pt-0">
                <v-text-field
                  v-model="password2"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min, passwordConfirmationRule]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Passwort wiederholen"
                  counter
                  required
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click.stop="show=false">Abbrechen</v-btn>
          <v-btn color="blue-grey darken-3" dark @click="registerWithEmail">Registrieren</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

      <script>
import firebase from "firebase";

export default {
  data: () => ({
    show1: false,
    password: "",
    password2: "",
    email: "",
    email2: "",
    alert: false,
    valid: true,
    rules: {
      required: value => !!value || "Pflichtfeld",
      min: v => v.length >= 8 || "Mindestens 8 Zeichen",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email Adresse falsch.";
      }
    }
  }),
  created() {
    console.log("1 im chat");
  },
  methods: {
    registerWithEmail() {
      if (this.$refs.form.validate()) {
        console.log("regeristing", this.email, this.password);
        const auth = firebase.auth();

        const promis = auth.createUserWithEmailAndPassword(
          this.email2,
          this.password2,
        );
        promis.then(data => console.log(data)).then(data => {
          console.log("user is created" + data);
          var user = firebase.auth().currentUser;
          console.log("user", user);
          user.updateProfile({
        displayName: this.firstname + " " + this.lastname,
        photoURL: "https://contatori.de/img/logo.svg"
    }).then( data => {
       console.log("user updated as well");
       this.show = false;
       this.$router.push("/")
    }, function(error) {
        console.log("user not updated");
    });
        }).catch(e => {
          console.log("error",e)
          this.errorMessage = e.message
          this.alert = true});
      }
      firebase.auth().onAuthStateChanged(firebaseUser => {
        if(firebaseUser){
          console.log(firebaseUser)
        } else{
          console.log('no user found')
        }
      })
    }
  },
  props: ["visible"],
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password == this.password2 || "Passwörter müssen gleich sein";
    },
    emailConfirmationRule() {
      return () =>
        this.email == this.email2 || "E-Mail-Adressen müssen gleich sein";
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.alert = false;
          this.$emit("close");
        }
      }
    }
  }
};
</script>