
<template>
  <div class="mx-auto mt-md-8 px-3 d-none d-md-flex" style="max-width:900px;">
    <v-row no-gutters v-model="show">
      <v-col cols="12" md="6">
        <v-breadcrumbs :items="getBreadCrumbs()" class="px-0"></v-breadcrumbs>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.v-breadcrumbs__item {
    color: #263238!important;

}
.v-breadcrumbs__item--disabled{
color: #a9b5bb!important;
}

</style>
<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      places: state => state.places.places, // state.<module>.<property>
      user: state => state.user.user,
      place: state => state.places.place,
      numerator: state => state.numerator.numerator,
      readings: state => state.readings.readings
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  data: () => ({
    breadCrumbs: [
      {
        text: this.place.street + " " + this.place.h_number,
        disabled: false,
        href: "breadcrumbs_dashboard"
      }
    ]
  }),
  methods: {
    getBreadCrumbs() {
      console.log("getting breadcrumbs")
      let tempCrumbs = [];
      if (this.place.place_id != null) {
        tempCrumbs[0] = {
          text: "Meine Objekte",
          disabled: false,
          href: "/"
        };
        tempCrumbs[1] = {
          text: this.place.street + " " + this.place.h_number,
          disabled: true,
          href: "/place/" + this.place.place_id
        };
        if (this.numerator.numerator_id != null && this.$route.params.numerator_id != null) {
          tempCrumbs[1].disabled = false;

          tempCrumbs[2] = {
            text: this.numerator.numerator_id,
            disabled: true,
            href:
              "/place/" +
              this.place.place_id +
              "/numerators/" +
              this.numerator.id
          };
        }
      }
      return tempCrumbs;
    }
  }
};
</script>