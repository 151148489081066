<template>
  <v-dialog v-model="show" max-width="700px">
        <v-card>
          <v-card-title>Neues Objekt erstellen</v-card-title>
          <v-card-text>
            <v-spacer></v-spacer>

            <template>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="newPlace.street"
                      :rules="nameRules"
                      label="Straße*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="0" md="1"></v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="newPlace.h_number"
                      :rules="numberRules"
                      label="Hausnummer*"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mb-6" no-gutters>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="newPlace.city"
                      :rules="nameRules"
                      label="Stadt*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="0" md="1"></v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="newPlace.zip"
                      :rules="numberRules"
                      label="Postleitzahl*"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </template>

            <small class="grey--text">*Pflichtfeld</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="show=false">Abbrechen</v-btn>
            <v-btn :disabled="!valid" color="primary" @click="validate">Objekt erstellen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  data: () => ({
        valid: true,
    newPlace: {
      h_number: "",
      street: "",
      zip: "",
      city: "",
      uid: ""
    },

    nameRules: [
      v => !!v || "Pflichtfeld"
      // v => (v && v.length <= 10) || "Name must be less than 10 characters"
    ],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    numberRules: [
      v => !!v || "Bitte Zahl eingeben",
      v => /^\d*$/.test(v) || "Nur Zahlen!"
    ],
    select: null,
  }),
  methods: {
    ...mapActions(["fetchPlaceList", "createPlace"]),
    showAlert(a){
      if (event.target.classList.contains('btn__content')) return;
      alert('Alert! \n' + a.name);
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.newPlace.uid = this.user.uid;
        this.createPlace(this.newPlace);


        this.fetchPlaceList(this.user.uid);
        this.show=false;
        this.reset();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  props: ["visible"],
  computed: {
    ...mapState({
      places: state => state.places.places, // state.<module>.<property>
      user: state => state.user.user
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  created(){
    console.log("hi from create place")
  }
};
</script>