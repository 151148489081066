<template>
<div>

  <v-dialog v-model="show" max-width="700px">

    <v-card>
        <v-alert
      text
      :value="alert"
      prominent
      type="error"
      transition="scale-transition"
      icon="mdi-cloud-alert"
    >
      Dieser Zähler enthält noch Messungen. Bitte löschen sie diese, bevor sie den Zähler löschen.
    </v-alert>
      <v-card-title>
       {{ numerator.numerator_id }} wirklich löschen?
        <br />
        <span>
          <small style="color: gray;"></small>
        </span>
      </v-card-title>

      <v-card-text>
        <v-spacer></v-spacer>
        <p>Sie können den Zähler nur löschen wenn keine Messungen mehr existieren</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click.stop="show=false">Abbrechen</v-btn>
        <v-btn color="error" @click="deleteNumeratorMethod">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

      <script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  data: () => ({
    alert: false,
  }),
  props: ["visible"],
  computed: {
     ...mapState({
      place: state => state.places.place,
      user: state => state.user.user,
      readings: state => state.readings.readings,
      numerator: state => state.numerator.numerator
    }),
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.alert = false
          this.$emit('close')
        }
      }
    }
  },
    methods: {
      ...mapActions(["deleteNumerator", "fetchNumerators"]),
      deleteNumeratorMethod() {
        console.log("deleate Numerator")
        console.log(this.readings.length);
        //TODO: check if there are any readings in this places
        if (this.readings.length  == 0) {
          console.log(this.place)
          let payload = { uid: this.user.uid, place_id: this.place.place_id, numerator_id: this.$route.params.numerator_id };
          this.deleteNumerator(payload);
          this.fetchNumerators(payload);
          console.log("yay it worked")
          this.show = false;
          this.$router.replace("/place/"+ this.place.place_id);
        } else{
          this.alert = true
        }
      },
    },

    created() {
      console.log("deleate place dialog created")
    }

  }
</script>