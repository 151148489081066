import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import places from './modules/places'
import numerator from './modules/numerator'
import readings from './modules/readings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    places,
    numerator,
    readings,
  }
})
