import firebase from "firebase";

const state = {
    user: {},
};
const getters = {
    getUserId: (state) => state.user.uid,
    getUser: (state) => state.user,
}
const mutations = {
    setUser(state, payload) {
        state.user = payload
    },
};
const actions = {
    fetchUserData({commit}){
        return new Promise(function (resolve, reject) {
        console.log("fetsching again...")
        firebase.auth().onAuthStateChanged(user =>{
            if(user){
                commit('setUser', user);
                resolve(user);
            } else{
            //   this.user = null;
            }
          })
        })
    },
    updateUserData({commit}, payload){
        commit('setUser', payload);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}




// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     user: {},
//     todos: [
//       { id: 1, text: '...', done: true },
//       { id: 2, text: '...', done: false }
//     ]
//   },
//   getters: {
//     doneTodos: state => {
//       return state.todos.filter(todo => todo.done)
//     },
//     getUserId: state => {
//       return state.user.uid
//     },
//   },
//   mutations: {
//     setUser (state, payload) {
//       state.user = payload
//     },
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

// console.log("index of store")
