
<template>
  <div>
    <!-- <div v-if="missingReading == true" class="color-red">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon>
            <v-icon medium color="red lighten-1" v-on="on">mdi-map-marker-alert-outline</v-icon>
          </v-btn>
        </template>
        <span>Status: Zählerstand Fehlt</span>
      </v-tooltip>
    </div> -->
    <div v-if="loadingMissingReading">
      <v-progress-circular indeterminate size="24" color="primary"></v-progress-circular>
    </div>
    <div v-if="!loadingMissingReading">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon>
            <v-icon medium color="green darken-2" v-on="on">mdi-map-marker-check-outline</v-icon>
          </v-btn>
        </template>
        <span>Status: Alle Zählerstände erfasst</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      places: state => state.places.places,
      user: state => state.user.user,
      place: state => state.places.place,
      numerator: state => state.numerator.numerator,
      readings: state => state.readings.readings
    })
  },
  props: {
    id: {
      type: String,
      required: true
    },
    numerator_id: {
      type: String,
      required: false
    }
  },
  data: () => ({
    loadingMissingReading: true,
    missingReading: Boolean
  }),

  methods: {
    ...mapActions([
      "fetchReadings",
      "fetchNumerator",
      "fetchPlace",
      "fetchUserData",
      "fetchNumerators"
    ]),
    GetStatusOfNumerator(data) {
      console.log("getting status of: " + data.id);
      var payload = {
        place_id: data.id,
        uid: this.user.uid,
        numerator_id: null
      };
      let firstReadingYear = null;

      if (data.numerator_id != null || data.numerator_id != "undefined") {
        payload.numerator_id = data.numerator_id;
      }

      console.log(payload);
      //fetch numerators for this place id

      if (payload.numerator_id != null) {
        this.fetchReadings(payload)
          .then(readings => {
            console.log("reading HIHI", readings);

            readings.forEach(reading => {
              console.log(reading);
              var currentTime = new Date();
              var currentYear = currentTime.getFullYear();
              var currentMonth = currentTime.getMonth() + 1;
              if (
                firstReadingYear == null ||
                firstReadingYear > reading.rawDate.getFullYear()
              ) {
                firstReadingYear = reading.rawDate.getFullYear();
              }

              if (currentMonth != 12) {
                if (
                  (reading.rawDate.getFullYear() == currentYear &&
                    reading.rawDate.getMonth() + 1 == 1 &&
                    reading.rawDate.getUTCDate() < 16) ||
                  (reading.rawDate.getFullYear() == currentYear - 1 &&
                    reading.rawDate.getMonth() + 1 == 12 &&
                    reading.rawDate.getUTCDate() > 15)
                ) {
                  console.log("status: GOOD");
                  this.loadingMissingReading = false;
                  if (this.missingReading != true) this.missingReading = false;
                } else {
                  if (firstReadingYear == currentYear) {
                    console.log("status: GOOD");
                    this.loadingMissingReading = false;
                    if (this.missingReading != true)
                      this.missingReading = false;
                  } else {
                    console.log("status: Bad");
                    this.loadingMissingReading = false;
                    this.missingReading = true;
                  }
                }
              } else {
                if (
                  reading.rawDate.getFullYear() == currentYear &&
                  reading.rawDate.getMonth() == currentMonth
                ) {
                  console.log("status: GOOD");
                  this.loadingMissingReading = false;
                  if (this.missingReading != true) this.missingReading = false;
                } else {
                  console.log("status: Bad");
                  this.loadingMissingReading = false;
                  this.missingReading = true;
                }
              }

              //check if there was a reading in the current year and the last year between the 15 of december and the 15 of january
              if (reading == null || reading == "undefined") {
                console.log("status: GOOD");
                this.loadingMissingReading = false;
                if (this.missingReading != true) this.missingReading = false;
              }
              // if there was one retusrn the ids of the numerators
            });
          })
          .catch(error => {
            console.log("no readings: 2663 ", error);
            this.loadingMissingReading = false;
            this.missingReading = false;
          });
      }
    }
  },
  created() {
    let payload = {
      id: this.id,
      numerator_id: this.numerator_id
    };
    this.GetStatusOfNumerator(payload);
  }
};
</script>